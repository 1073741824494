<div class="filter-sidebar">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="column" style="height: 100%;">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <div class="header">{{ratePlans.length}} Active Plan{{ratePlans.length > 1? 's' : ''}}</div>
            <div fxFlex="20%">
                <mat-dialog-actions>
                    <button mat-button mat-dialog-close class="close-btn">
                        <mat-icon class="close-icon">
                            close
                        </mat-icon>
                    </button>
                </mat-dialog-actions>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout="column" fxLayoutGap="15px" class="filter-list" *ngIf="ratePlans.length != 0">
            <div fxLayout="column" fxLayoutGap="15px" *ngFor="let plan of ratePlans;">
                <div fxLayout="row" fxLayoutGap="10px">
                    <div class="plan-head" style="font-weight: 500;">
                        {{plan.NAME}}
                    </div>
                    <div>
                        <button class="active-btn" style="color: #5FC463;"> <!--[ngStyle]="{'color': currentActive === i ? '#D50000' : '#5FC463' }"-->
                            Active
                        </button>
                    </div>
                </div>
                <div>
                    <button class="segment-btn">
                        {{plan.MARKET_SEGMENT.NAME}}
                    </button>
                </div>
                <div class="nested-menu-list">
                    <ul>
                        <li *ngFor="let facility of plan.ROOM_FACILITIES">
                            <div class="nested-menu-text">{{facility.NAME}}</div>
                        </li>
                    </ul>
                </div>
                <!-- <div class="plan-head" style="font-weight: 700;">
                    5400
                </div> -->
                <div fxLayout="row" fxLayoutGap="20%">
                    <button class="action-btn" [disabled]="accessType == 2">
                        Disable
                    </button>
                    <button class="action-btn" (click)="updateRatePlan(plan.ID)" [disabled]="accessType == 2">
                        Edit
                    </button>
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>
        <div fxLayout="column" class="zero-plans" *ngIf="ratePlans.length == 0">
            No rate plans available
        </div>
    </div>
</div>