<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
  <div>
    <img src="../../../../assets/images/warning.png">
  </div>
  <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
    <p>Dear</p>
    <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
  </span>
  <div class="dasboard-subheading">
    <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
      functionalities of the system</p>
  </div>
  <div>
    <button class="back-button" (click)="logout()">Go Back</button>
  </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
  <div class="right-panel">
    <div class="overlay" *ngIf="loading">
      <div class="overlay__inner">
        <div class="overlay__content"><span class="spinner"></span></div>
      </div>
    </div>
    <div fxLayout="column" class="content">
      <div class="main-header">Configurations</div>
      <div class="input-container">
        <mat-card class="card">
          <div fxLayout="row" style="height:inherit">
            <div fxLayout="column" class="services-buttons" fxFlex="20%">
              <button mat-button class="service-button" (click)="showAreas()">
                <div fxLayout="row" fxFlex="190px" fxLayoutAlign="space-between center">
                  <span class="button-label">Area</span>
                  <div>
                    <mat-icon class="arrow-icon">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
              <mat-divider></mat-divider>
              <button mat-button class="service-button" (click)="showRooms()">
                <div fxLayout="row" fxFlex="190px" fxLayoutAlign="space-between center">
                  <span class="button-label">Room</span>
                  <div>
                    <mat-icon class="arrow-icon">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
              <mat-divider></mat-divider>
              <button mat-button class="service-button" (click)="showShifts()">
                <div fxLayout="row" fxFlex="190px" fxLayoutAlign="space-between center">
                  <span class="button-label">Shift</span>
                  <div>
                    <mat-icon class="arrow-icon">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
              <mat-divider></mat-divider>
              <button mat-button class="service-button" (click)="showRoomAllocations()">
                <div fxLayout="row" fxFlex="190px" fxLayoutAlign="space-between center">
                  <span class="button-label"> Room Allocation</span>
                  <div>
                    <mat-icon class="arrow-icon">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
              <mat-divider></mat-divider>
              <button mat-button class="service-button" (click)="showServiceTiming()">
                <div fxLayout="row" fxFlex="190px" fxLayoutAlign="space-between center">
                  <span class="button-label"> Service Timing</span>
                  <div>
                    <mat-icon class="arrow-icon">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
              <mat-divider></mat-divider>
              <button mat-button class="service-button" (click)="showThemeInfo()">
                <div fxLayout="row" fxFlex="190px" fxLayoutAlign="space-between center">
                  <span class="button-label"> Theme</span>
                  <div>
                    <mat-icon class="arrow-icon">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
              <mat-divider></mat-divider>
              <button mat-button class="service-button" (click)="showTaxes()">
                <div fxLayout="row" fxFlex="190px" fxLayoutAlign="space-between center">
                  <span class="button-label">Tax</span>
                  <div>
                    <mat-icon class="arrow-icon">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
              <mat-divider></mat-divider>
              <button mat-button class="service-button" (click)="showCancellationPolicy()">
                <div fxLayout="row" fxFlex="190px" fxLayoutAlign="space-between center">
                  <span class="button-label">Cancellation Policy</span>
                  <div>
                    <mat-icon class="arrow-icon">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
              <mat-divider></mat-divider>
              <button mat-button class="service-button" (click)="showMarketSegments()">
                <div fxLayout="row" fxFlex="190px" fxLayoutAlign="space-between center">
                  <span class="button-label">Market Segment</span>
                  <div>
                    <mat-icon class="arrow-icon">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
              <mat-divider></mat-divider>
              <button mat-button class="service-button" (click)="showTierCategory()">
                <div fxLayout="row" fxFlex="190px" fxLayoutAlign="space-between center">
                  <span class="button-label">Tier Category</span>
                  <div>
                    <mat-icon class="arrow-icon">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
              <mat-divider></mat-divider>
            </div>
            <mat-divider [vertical]="true" style="min-height: 71vh;"></mat-divider>
            <div fxLayout="column" *ngIf="showArea" class="container" fxFlex="78%" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutAlign="space-between baseline" style="width:100%">
                <div class="header" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                  Area
                  <button class="refresh-btn"><span class="material-symbols-outlined" (click)="refresh()"
                      style="font-size: 19px;">
                      refresh
                    </span></button>
                </div>
                <div>
                  <input [formControl]="searchVal" matInput class="menu-search" placeholder="Search">
                  <mat-icon matSuffix class="search_icon">search</mat-icon>
                  <button class="search-btn" (click)="searchArea()">
                    Search
                  </button>
                  <button class="add-btn" (click)="addArea()" [disabled]="accessType == 2">
                    + Add Area
                  </button>
                </div>
              </div>
              <div class="table-scroll">
                <table mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="30%"> Name
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.NAME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="60%">
                      Description
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.DESCRIPTION}}</td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="10%"> Action
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <div fxLayout="row" fxLayoutAlign="center">
                        <button mat-icon-button (click)="editArea(row.ID)" [disabled]="accessType == 2">
                          <span class="material-icons-outlined">edit</span>
                        </button>
                        <button mat-icon-button (click)="deleteArea(row.ID)" [disabled]="accessType == 2">
                          <span class="material-icons-outlined" style="color:#FF4242;">delete_outline</span>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="areaColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: areaColumns;"></tr>
                </table>
              </div>
            </div>

            <div fxLayout="column" *ngIf="showRoom" class="container" fxFlex="78%" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutAlign="space-between baseline" style="width:100%">
                <div class="header" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                  Room
                  <button class="refresh-btn"><span class="material-symbols-outlined" (click)="refresh()"
                      style="font-size: 19px;">
                      refresh
                    </span></button>
                </div>
                <div>
                  <input matInput [formControl]="searchVal" class="menu-search" placeholder="Search">
                  <mat-icon matSuffix class="search_icon">search</mat-icon>
                  <button class="search-btn" (click)="searchRoom()">
                    Search
                  </button>
                  <button class="add-btn" (click)="addRoom()" [disabled]="accessType == 2">
                    + Add Room
                  </button>
                </div>
              </div>
              <div class="table-scroll">
                <table mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="20%"> Room Name
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.NAME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="area">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="20%"> Area
                      Name
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.AREA.NAME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="50%">
                      Description
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.DESCRIPTION}}</td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="10%"> Action
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <div fxLayout="row" fxLayoutAlign="center">
                        <button mat-icon-button (click)="editRoom(row.ID)" [disabled]="row.DISABLED || accessType == 2">
                          <span class="material-icons-outlined">edit</span>
                        </button>
                        <button mat-icon-button (click)="deleteRoom(row.ID)"
                          [disabled]="row.DISABLED || accessType == 2">
                          <span class="material-icons-outlined" style="color:#FF4242;">delete_outline</span>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="roomColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: roomColumns;"></tr>
                </table>
              </div>
            </div>

            <div fxLayout="column" *ngIf="showShift" class="container" fxFlex="78%" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutAlign="space-between baseline" style="width:100%">
                <div class="header" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                  Shift
                  <button class="refresh-btn"><span class="material-symbols-outlined" (click)="refresh()"
                      style="font-size: 19px;">
                      refresh
                    </span></button>
                </div>
                <div>
                  <input matInput [formControl]="searchVal" class="menu-search" placeholder="Search">
                  <mat-icon matSuffix class="search_icon">search</mat-icon>
                  <button class="search-btn" (click)="searchShift()">
                    Search
                  </button>
                  <button class="add-btn" (click)="addShift()" [disabled]="accessType == 2">
                    + Add Shift
                  </button>
                </div>
              </div>
              <div class="table-scroll">
                <table mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="20%"> Name
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.NAME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="20%">
                      Description
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.DESCRIPTION}}</td>
                  </ng-container>
                  <ng-container matColumnDef="from">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="20%"> From
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.SHIFT_FROM.TIME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="to">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="20%"> To </th>
                    <td mat-cell *matCellDef="let row">{{row.SHIFT_TO.TIME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="20%"> Action
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <div fxLayout="row" fxLayoutAlign="center">
                        <button mat-icon-button (click)="editShift(row.ID)" [disabled]="accessType == 2">
                          <span class="material-icons-outlined">edit</span>
                        </button>
                        <button mat-icon-button (click)="deleteShift(row.ID)" [disabled]="accessType == 2">
                          <span class="material-icons-outlined" style="color:#FF4242;">delete_outline</span>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="shiftColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: shiftColumns;"></tr>
                </table>
              </div>
            </div>

            <div fxLayout="column" *ngIf="showRoomAllocation" class="container" fxFlex="78%">
              <div fxLayout="row" fxLayoutAlign="space-between">
                <div class="header">
                  Room Allocation
                </div>
                <div>
                  <button class="add-btn" (click)="addAllocation()" [disabled]="accessType == 2">
                    + Allocate Room
                  </button>
                </div>
              </div>
              <div class="table-scroll">
                <table mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> User </th>
                    <td mat-cell *matCellDef="let row">{{row.FIRST_NAME}} {{row.LAST_NAME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Role </th>
                    <td mat-cell *matCellDef="let row">{{row.ROLE.NAME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="service">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Service </th>
                    <td mat-cell *matCellDef="let row">
                      <ng-container *ngFor="let value of row.SERVICE">
                        {{value.NAME}}
                      </ng-container>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="rooms">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Rooms </th>
                    <td mat-cell *matCellDef="let row">
                      <span *ngFor="let data of row.ROOMS; let isLast=last">{{data.NAME}}{{isLast
                        ? ''
                        : ','}}</span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="shift">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Shift </th>
                    <td mat-cell *matCellDef="let row">{{row.WORK_SHIFT?.SHIFT_FROM?.TIME}}
                      -{{row.WORK_SHIFT?.SHIFT_TO?.TIME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                    <td mat-cell *matCellDef="let row">
                      <div fxLayout="row" fxLayoutAlign="center">
                        <button mat-icon-button (click)="editAllocation(row.ID)" [disabled]="accessType == 2">
                          <span class="material-icons-outlined">edit</span>
                        </button>
                        <button mat-icon-button (click)="deleteAllocation(row.ID)" [disabled]="accessType == 2">
                          <span class="material-icons-outlined" style="color:#FF4242;">delete_outline</span>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="roomAllocationColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: roomAllocationColumns;"></tr>
                </table>
              </div>
            </div>

            <div fxLayout="column" *ngIf="showServiceTimings" class="container" fxFlex="78%">
              <div fxLayout="row" fxLayoutAlign="space-between">
                <div class="header">
                  Service Timing
                </div>
              </div>
              <div class="table-scroll">
                <table mat-table [dataSource]="dataSource" style="margin-top: 2%;">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 25%"> Name </th>
                    <td mat-cell *matCellDef="let row">{{row.NAME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="from">
                    <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 20%"> From </th>
                    <td mat-cell *matCellDef="let row">{{row.FROM.TIME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="to">
                    <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 20%"> To </th>
                    <td mat-cell *matCellDef="let row">{{row.TO.TIME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header" style="width: 35%"> Action </th>
                    <td mat-cell *matCellDef="let row">
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <button fxFlex="20%" mat-icon-button (click)="editServiceTiming(row.ID, row.NAME)"
                          [disabled]="accessType == 2">
                          <span class="material-icons-outlined">edit</span>
                        </button>
                        <label fxFlex="25%" class="switch" style="margin-top: 6px">
                          <input #checkbox type="checkbox" [checked]="row.ENABLED" [disabled]="accessType == 2"
                            (change)="enableServiceTiming(checkbox.checked, row.ID, row.FROM.ID, row.TO.ID)">
                          <span class="slider round"></span>
                        </label>
                        <div fxFlex="55%" *ngIf="!row.ENABLED" style="font-size: 13px;">24/7 available</div>
                        <div fxFlex="55%" *ngIf="row.ENABLED" style="font-size: 13px;">Disable to make it 24/7 available
                        </div>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="serviceColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: serviceColumns;"></tr>
                </table>
              </div>
            </div>

            <div fxLayout="column" *ngIf="showTheme" class="container" fxLayoutGap="27px" fxFlex="78%">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div class="header">
                  Theme
                </div>
                <div fxLayout="row">
                  <span style="padding-top: 7px;">{{isEnabled ? 'Custom':'Default'}}</span>
                  <label class="switch-theme">
                    <input #checkbox type="checkbox" [checked]="isEnabled" (change)="enableTheme(checkbox.checked)"
                      [disabled]="accessType == 2">
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <mat-card class="theme-card" fxLayout="column" fxLayoutGap="27px">
                <div fxLayout="row" fxLayoutGap="27px" fxLayoutAlign="center center">
                  <div>
                    Background:
                  </div>
                  <div>
                    <input class="input-field" [style.background]="currentBackgroundColor"
                      [(colorPicker)]="currentBackgroundColor"
                      (colorPickerClose)="selectBackgroundColor('colorPickerClose', $event)" />
                  </div>
                  <div>
                    <input matInput class="input-field" [formControl]="background" [(ngModel)]="currentBackgroundColor">
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                  <div style="margin-right: 52px;">
                    Selection:
                  </div>
                  <div style="margin-right: 27px;">
                    <input class="input-field" [style.background]="currentSelectionColor"
                      [(colorPicker)]="currentSelectionColor"
                      (colorPickerClose)="selectSelectionColor('colorPickerClose', $event)" />
                  </div>
                  <div>
                    <input matInput class="input-field" [formControl]="selection" [(ngModel)]="currentSelectionColor">
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                  <div style="margin-right: 71px;">
                    Button:
                  </div>
                  <div style="margin-right: 27px;">
                    <input class="input-field" [style.background]="currentButtonColor"
                      [(colorPicker)]="currentButtonColor"
                      (colorPickerClose)="selectButtonColor('colorPickerClose', $event)" />
                  </div>
                  <div>
                    <input matInput class="input-field" [formControl]="button" [(ngModel)]="currentButtonColor">
                  </div>
                </div>
              </mat-card>
              <div fxLayout="row" fxLayoutGap="27px" fxLayoutAlign="end">
                <button mat-stroked-button class="reset-button" (click)="cancel()" [disabled]="accessType == 2">
                  Reset
                </button>
                <button mat-stroked-button class="save-button" (click)="saveTheme()" [disabled]="accessType == 2">
                  Save
                </button>
              </div>
            </div>

            <div fxLayout="column" *ngIf="showTax" class="container" fxFlex="78%" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutAlign="space-between center" style="width: 100%">
                <div class="header" fxLayoutAlign="center center" fxLayoutGap="10px">
                  Tax
                  <button class="refresh-btn"><span class="material-symbols-outlined" (click)="refresh()"
                      style="font-size: 19px;">
                      refresh
                    </span>
                  </button>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                  <mat-select class="filter-menu" appearance="fill" [formControl]="filterByService"
                    placeholder="Select Service">
                    <mat-option *ngFor="let service of filterTaxByService"
                      [value]="service.ID">{{service.NAME}}</mat-option>
                  </mat-select>
                  <input [formControl]="searchVal" matInput class="menu-search-tax" placeholder="Search">
                  <mat-icon matSuffix class="search_icon_tax">search</mat-icon>
                  <button class="search-btn" (click)="searchTax()">
                    Search
                  </button>
                  <button class="addTax-btn" (click)="addTax()" [disabled]="accessType == 2">
                    + Add Tax
                  </button>
                </div>
              </div>
              <div class="table-scroll">
                <table mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Name </th>
                    <td mat-cell *matCellDef="let row">{{row.NAME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="percentage">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Percentage </th>
                    <td mat-cell *matCellDef="let row">{{row.PERCENTAGE}}%</td>
                  </ng-container>
                  <ng-container matColumnDef="service">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Service </th>
                    <td mat-cell *matCellDef="let row">{{row.SERVICE_ID.NAME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                    <td mat-cell *matCellDef="let row">
                      <div fxLayout="row" fxLayoutAlign="center">
                        <button mat-icon-button (click)="editTax(row.ID)" [disabled]="accessType == 2">
                          <span class="material-icons-outlined">edit</span>
                        </button>
                        <button mat-icon-button (click)="deleteTax(row.ID)" [disabled]="accessType == 2">
                          <span class="material-icons-outlined" style="color:#FF4242;">delete_outline</span>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="taxColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: taxColumns;"></tr>
                </table>
              </div>
            </div>

            <div fxLayout="column" *ngIf="showCancellation" class="container" fxFlex="78%">
              <div fxLayout="row" fxLayoutAlign="space-between">
                <div class="header">
                  Cancellation Policy
                </div>
                <div>
                  <button class="add-btn" (click)="editCancellation()" [disabled]="accessType == 2">
                    + Add Cancellation
                  </button>
                </div>
              </div>
              <div class="table-scroll">
                <table mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Name </th>
                    <td mat-cell *matCellDef="let row">{{ row.NAME }}</td>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Description </th>
                    <td mat-cell *matCellDef="let row">{{ row.DESCRIPTION }}</td>
                  </ng-container>

                  <ng-container matColumnDef="ratePlans">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Rate Plans </th>
                    <td mat-cell *matCellDef="let row">{{ row.roomRatePlanNames }}</td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                    <td mat-cell *matCellDef="let row">
                      <ng-container *ngIf="row.ID"> <!-- Use ng-container to wrap the action buttons -->
                        <div fxLayout="row" fxLayoutAlign="center">
                          <button mat-icon-button (click)="editCancellation(row.ID)" [disabled]="accessType == 2">
                            <span class="material-icons-outlined">edit</span>
                          </button>
                          <button mat-icon-button (click)="deleteCancellation(row.ID)" [disabled]="accessType == 2">
                            <span class="material-icons-outlined" style="color:#FF4242;">delete_outline</span>
                          </button>
                        </div>
                      </ng-container>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="cancellationColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: cancellationColumns;"></tr>
                </table>
              </div>
            </div>

            <div fxLayout="column" *ngIf="showMarketSegment" class="container" fxFlex="78%" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutAlign="space-between baseline" style="width:100%">
                <div class="header" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                  Market Segment
                  <button class="refresh-btn"><span class="material-symbols-outlined" (click)="refresh()"
                      style="font-size: 19px;">
                      refresh
                    </span></button>
                </div>
                <div>
                  <input [formControl]="searchVal" matInput class="menu-search" placeholder="Search">
                  <mat-icon matSuffix class="search_icon">search</mat-icon>
                  <button class="search-btn" (click)="searchMarketSegment()">
                    Search
                  </button>
                  <button class="add-btn" (click)="addMarketSegment()" [disabled]="accessType == 2">
                    + Add Market Segment
                  </button>
                </div>
              </div>
              <div class="table-scroll">
                <table mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="30%"> Name
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.NAME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="60%">
                      Description
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.DESCRIPTION}}</td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="10%"> Action
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <div fxLayout="row" fxLayoutAlign="center">
                        <button mat-icon-button (click)="editMarketSegment(row.ID)" [disabled]="accessType == 2">
                          <span class="material-icons-outlined">edit</span>
                        </button>
                        <button mat-icon-button (click)="deleteMarketSegment(row.ID)" [disabled]="accessType == 2">
                          <span class="material-icons-outlined" style="color:#FF4242;">delete_outline</span>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="areaColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: areaColumns;"></tr>
                </table>
              </div>
            </div>

            <div fxLayout="column" *ngIf="showTierCategories" class="container" fxFlex="78%" fxLayoutGap="10px">
              <div fxLayout="row" fxLayoutAlign="space-between baseline" style="width:100%">
                <div class="header" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                  Tier Category
                  <button class="refresh-btn"><span class="material-symbols-outlined" (click)="refresh()"
                      style="font-size: 19px;">
                      refresh
                    </span></button>
                </div>
                <div>
                  <input [formControl]="searchVal" matInput class="menu-search" placeholder="Search">
                  <mat-icon matSuffix class="search_icon">search</mat-icon>
                  <button class="search-btn" (click)="searchTierCategory()">
                    Search
                  </button>
                  <button class="add-btn" (click)="addTierCategory()" [disabled]="accessType == 2">
                    + Add Tier Category
                  </button>
                </div>
              </div>
              <div class="table-scroll">
                <table mat-table [dataSource]="dataSource">
                  <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="30%"> Name
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.NAME}}</td>
                  </ng-container>
                  <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="60%">
                      Description
                    </th>
                    <td mat-cell *matCellDef="let row">{{row.DESCRIPTION}}</td>
                  </ng-container>
                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header" width="10%"> Action
                    </th>
                    <td mat-cell *matCellDef="let row">
                      <div fxLayout="row" fxLayoutAlign="center">
                        <button mat-icon-button (click)="editTierCategory(row.ID)" [disabled]="accessType == 2">
                          <span class="material-icons-outlined">edit</span>
                        </button>
                        <button mat-icon-button (click)="deleteTierCategory(row.ID)" [disabled]="accessType == 2">
                          <span class="material-icons-outlined" style="color:#FF4242;">delete_outline</span>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="areaColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: areaColumns;"></tr>
                </table>
              </div>
            </div>

          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>