<div fxLayout="column" class="add-user">
    <div class="overlay" *ngIf="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <span mat-dialog-title class="header">{{data ? 'Edit Market Segment':'Add New Market Segment'}}</span>
        </div>
        <div>
            <button mat-button class="close-btn" (click)="cancel()">
                <mat-icon class="close-icon">
                    close
                </mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="marketSegmentForm">
        <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="37px">
            <div fxLayout="column" fxLayoutGap="41px">
                <div>
                    <div class="box-name">
                        Name
                    </div>
                    <div>
                        <input type="text" class="text-box" formControlName="name" placeholder="Enter Name">
                    </div>
                    <mat-error class="error"
                        *ngIf="marketSegmentForm.controls['name'].touched && marketSegmentForm.get('name').hasError('required')">
                        Required
                    </mat-error>
                </div>
                <div>
                    <div class="box-name">
                        Description
                    </div>
                    <div>
                        <textarea type="text" class="text-area" formControlName="description"
                            placeholder="Enter Description"></textarea>
                    </div>
                    <mat-error class="error"
                        *ngIf="marketSegmentForm.controls['description'].touched && marketSegmentForm.get('description').hasError('required')">
                        Required
                    </mat-error>
                </div>
            </div>

        </div>
        <div>
            <mat-dialog-actions align="end" class="actions">
                <button mat-stroked-button class="button" (click)="cancel()">
                    Cancel
                </button>
                <button mat-stroked-button [ngClass]="this.marketSegmentForm.invalid ? 'button' : 'save-button'"
                    [disabled]="this.marketSegmentForm.invalid" (click)="addMarketSegment()">
                    Save
                </button>
            </mat-dialog-actions>
        </div>
    </form>
</div>