<div class="dashboard-container" *ngIf="mobile" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div>
        <img src="../../../../assets/images/warning.png">
    </div>
    <span class="dashboard-admin-heading" fxLayout="row" fxLayoutGap="5px">
        <p>Dear</p>
        <p style="font-family: Montserrat-SemiBold;">{{userName}}!</p>
    </span>
    <div class="dasboard-subheading">
        <p style="text-align: center;">Please move to a larger device for seamlessly accessing all
            functionalities of the system</p>
    </div>
    <div>
        <button class="back-button" (click)="logout()">Go Back</button>
    </div>
</div>
<div class="dashboard-content" *ngIf="!mobile">
    <div class="right-panel">
        <div class="overlay" *ngIf="loading">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>
        <div fxLayout="column" fxLayoutGap="30px" *ngIf="searchPlans">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="bulk-head">
                    Search for Rooms
                </div>
            </div>
            <form [formGroup]="searchRatePlanForm" fxLayout="column" fxLayoutGap="3%">
                <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="30px">
                    <!-- <div fxLayout="row" fxLayoutGap="40px">
                        <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                            <div class="box-name">
                                Select Property
                            </div>
                            <div>
                                <mat-select class="text-box" appearance="fill" formControlName="planProperties"
                                    placeholder="Select" multiple>
                                    <mat-option [value]="1">A</mat-option>
                                    <mat-option [value]="2">B</mat-option>
                                    <mat-option [value]="3">C</mat-option> -->
                                    <!-- <mat-option *ngFor="let room of roomTypesList" [value]="room.ID">
                                        {{room.NAME}}
                                    </mat-option> -->
                                <!-- </mat-select>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div fxLayout="row" fxLayoutGap="40px">
                        <div fxLayout="column" fxLayoutGap="7px">
                            <div class="box-name">
                                Select Booking Service Type
                            </div>
                            <div>
                                <mat-radio-group (click)="$event.stopPropagation()" formControlName="bookingService">
                                    <mat-radio-button class="radio-btn" [value]="1">
                                        <span class="radio-label">Per Night</span>
                                    </mat-radio-button>
                                    <mat-radio-button class="radio-btn" [value]="2">
                                        <span class="radio-label">Per Hour</span>
                                    </mat-radio-button>
                                    <mat-radio-button class="radio-btn" [value]="3">
                                        <span class="radio-label">Per Day</span>
                                    </mat-radio-button>
                                    <mat-radio-button class="radio-btn" [value]="4">
                                        <span class="radio-label">Per Month</span>
                                    </mat-radio-button> -->
                                    <!-- <mat-radio-button class="radio-btn" *ngFor="let res of responses" [value]="res.ID" (change)="toggle($event, 'overBookingLimit')">
                                        <span class="radio-label">{{res.NAME}}</span>
                                    </mat-radio-button> -->
                                <!-- </mat-radio-group>
                            </div>
                        </div>
                    </div> -->
                    <div fxLayout="row" fxLayoutGap="25px">
                        <div fxLayout="column" fxFlex="31%" fxLayoutGap="7px">
                            <div class="box-name">
                                Check-In Date
                            </div>
                            <div class="text-box-date">
                                <input matInput [matDatepicker]="picker1" (dateChange)="selectDate($event)"
                                    formControlName="checkInDate" class="date-box" placeholder="Select"
                                    [min]="currentDate" readonly>
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="31%" fxLayoutGap="7px">
                            <div class="box-name">
                                Check-Out Date
                            </div>
                            <div class="text-box-date">
                                <input matInput [matDatepicker]="picker2" formControlName="checkOutDate"
                                    class="date-box" placeholder="Select" [min]="minToDate" readonly>
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="35px">
                        <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                            <div class="box-name">
                                Number Of Rooms
                            </div>
                            <div>
                                <input type="number" min="1" step="1"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="text-box"
                                    formControlName="noOfRooms" placeholder="Enter">
                            </div>
                        </div>
                        <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                            <div class="box-name">
                                No. of people per Room 
                            </div>
                            <div>
                                <input class="text-box" type="text" placeholder="Select" formControlName="roomOccupants" autocomplete="off"
                                    [matMenuTriggerFor]="menu" readonly />
                                <mat-menu #menu="matMenu" (closed)="menuClosed()">
                                    <div class="menu-bar">
                                        <div fxLayout="column" fxLayoutGap="15px">
                                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                                <div fxFlex="55%" fxLayout="column">
                                                    <span class="guest-type">Adults</span>
                                                    <span class="guest-age">Ages 13 or above</span>
                                                </div>
                                                <div fxFlex="45%" fxLayout="row" fxLayoutAlign="space-between center">
                                                    <button class="remove-button" (click)="decreaseAdultCount($event)">
                                                        <mat-icon>remove</mat-icon>
                                                    </button>
                                                    <span class="no-button">{{ adultCount }}</span>
                                                    <button class="remove-button" (click)="increaseAdultCount($event)">
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                                <div fxFlex="55%" fxLayout="column">
                                                    <span class="guest-type">Children</span>
                                                    <span class="guest-age">Ages 2-12</span>
                                                </div>
                                                <div fxFlex="45%" fxLayout="row" fxLayoutAlign="space-between center">
                                                    <button class="remove-button" (click)="decreaseChildCount($event)">
                                                        <mat-icon>remove</mat-icon>
                                                    </button>
                                                    <span class="no-button">{{ childCount }}</span>
                                                    <button class="remove-button" (click)="increaseChildCount($event)">
                                                        <mat-icon>add</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="center center" style="padding-top: 10px;">
                                                <button mat-button class="count-btn" (click)="updateCounts()">Done</button>
                                            </div>
                                        </div>
                                    </div>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="40px">
                        <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                            <div class="box-name">
                                Room Category
                            </div>
                            <div>
                                <mat-select class="text-box" appearance="fill" formControlName="roomCategory"
                                    placeholder="Select">
                                    <mat-option *ngFor="let category of roomCategories" [value]="category.ID">
                                        {{category.NAME}}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="40px">
                        <div fxLayout="column" fxFlex="30%" fxLayoutGap="7px">
                            <div class="box-name">
                                Kind of Reservation
                            </div>
                            <div>
                                <mat-radio-group (click)="$event.stopPropagation()" formControlName="reservationType">
                                    <mat-radio-button class="radio-btn" [value]="1">
                                        <span class="radio-label">Single</span>
                                    </mat-radio-button>
                                    <mat-radio-button class="radio-btn" [value]="2">
                                        <span class="radio-label">Group</span>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                    <button mat-button class="save-btn" (click)="searchRatePlans()" [disabled]="searchRatePlanForm.invalid">Search</button>
                    <button mat-button class="cancel-btn" (click)="cancelSearch()">Cancel</button>
                </div>
            </form>
        </div>
        <div fxLayout="column" fxLayoutGap="20px" *ngIf="availableRooms">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                <div class="back-arrow" (click)="backToSearchRooms()">
                    <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
                </div>
                <div fxFlex="90%" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="7px">
                    <div fxLayout="row" fxFlex="20%">
                        <input type="number" min="1" step="1" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            class="search-room" [formControl]="searchWithRooms" placeholder="Number of rooms">
                    </div>
                    <div fxLayout="row" fxFlex="30%">
                        <img class="occup-icon" src="../../../../../assets/images/date-range.png">
                        <mat-form-field appearance="outline" class="search-date">
                            <mat-date-range-input [rangePicker]="picker" [min]="currentDate">
                                <input matStartDate [formControl]="searchWithStartDate" readonly>
                                <input matEndDate [formControl]="searchWithEndDate" readonly>
                            </mat-date-range-input>
                            <mat-datepicker-toggle matIconSuffix [for]="picker">
                                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                            </mat-datepicker-toggle>
                            <mat-date-range-picker #picker>
                                <mat-date-range-picker-actions>
                                    <button mat-button matDateRangePickerCancel>Cancel</button>
                                    <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                                </mat-date-range-picker-actions>
                            </mat-date-range-picker>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxFlex="25%">
                        <img class="occup-icon" src="../../../../../assets/images/vaccants.png">
                        <input class="search-occupant" [formControl]="searchWithOccupants" autocomplete="off"
                            [matMenuTriggerFor]="menu" readonly />
                        <mat-menu #menu="matMenu" (closed)="menuClosed()">
                            <div class="menu-bar">
                                <div fxLayout="column" fxLayoutGap="15px">
                                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                                        <div fxFlex="55%" fxLayout="column">
                                            <span class="guest-type">Adults</span>
                                            <span class="guest-age">Ages 13 or above</span>
                                        </div>
                                        <div fxFlex="45%" fxLayout="row" fxLayoutAlign="space-between center">
                                            <button class="remove-button" (click)="decreaseAdultCount($event)">
                                                <mat-icon>remove</mat-icon>
                                            </button>
                                            <span class="no-button">{{ adultCount }}</span>
                                            <button class="remove-button" (click)="increaseAdultCount($event)">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div fxFlex="55%" fxLayout="column">
                                            <span class="guest-type">Children</span>
                                            <span class="guest-age">Ages 2-12</span>
                                        </div>
                                        <div fxFlex="45%" fxLayout="row" fxLayoutAlign="space-between center">
                                            <button class="remove-button" (click)="decreaseChildCount($event)">
                                                <mat-icon>remove</mat-icon>
                                            </button>
                                            <span class="no-button">{{ childCount }}</span>
                                            <button class="remove-button" (click)="increaseChildCount($event)">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="center center" style="padding-top: 10px;">
                                        <button mat-button class="count-btn" (click)="updateCounts()">Done</button>
                                    </div>
                                </div>
                            </div>
                        </mat-menu>
                        <img class="select-icon" src="../../../../../assets/images/Vector.png">
                    </div>
                </div>
                <button mat-button class="save-btn" (click)="searchRatePlans()">Search</button>
            </div>
            <div fxLayout="column" class="detail-table" style="height: inherit;">
                <div fxLayout="row" class="detail-header">
                    <div fxFlex="20%">Room Type</div>
                    <mat-divider [vertical]="true" style="min-height: 10vh;"></mat-divider>
                    <div fxFlex="30%">Room Details</div>
                    <mat-divider [vertical]="true" style="min-height: 10vh;"></mat-divider>
                    <!-- <div fxFlex="10%">Availability</div>
                    <mat-divider [vertical]="true" style="min-height: 10vh;"></mat-divider> -->
                    <div fxFlex="15%">Rates</div>
                    <mat-divider [vertical]="true" style="min-height: 10vh;"></mat-divider>
                    <div fxFlex="35%">Preferences</div>
                </div>
                <div fxLayout="column" *ngFor="let types of availableRoomTypes">
                    <div fxLayout="row" fxFlex="100%" *ngIf="!!types.ROOM_TYPE">
                        <div fxFlex="20%" fxLayout="column" class="room-details">
                            <div class="main-values">{{types.ROOM_TYPE?.CATEGORY_ID?.NAME}}</div>
                            <div class="sub-values">{{types.ROOM_TYPE?.NAME}}</div>
                        </div>
                        <mat-divider [vertical]="true"></mat-divider>
                        <div fxFlex="30%" fxLayout="column" fxLayoutGap="15px" class="room-details" *ngIf="!!types.ROOM_TYPE">
                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center" *ngFor="let amenity of types.ROOM_TYPE?.ROOM_AMENITIES_ID">
                                <img src="../../../../assets/images/tick.png">
                                <div>{{amenity.AMENITIES_ID.NAME}}</div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center">
                                <img src="../../../../assets/images/tick.png" *ngIf="types.ROOM_TYPE?.SMOKING_ALLOWED_ID?.ID == 2">
                                <img src="../../../../assets/images/cancel.png" *ngIf="types.ROOM_TYPE?.SMOKING_ALLOWED_ID?.ID == 1">
                                <div>Smoking</div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center">
                                <img src="../../../../assets/images/tick.png">
                                <div>{{types.ROOM_TYPE?.BATHROOM_ID?.NAME}} Attached Bathrooms</div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center">
                                <img src="../../../../assets/images/tick.png" *ngIf="types.ROOM_TYPE?.SMOKING_ALLOWED_ID?.ID == 2">
                                <img src="../../../../assets/images/cancel.png" *ngIf="types.ROOM_TYPE?.SMOKING_ALLOWED_ID?.ID == 1">
                                <div *ngIf="types.ROOM_TYPE?.WHEELCHAIR_ACCESSIBLE_ID?.ID == 1">Wheel-chair accessible</div>
                                <div *ngIf="types.ROOM_TYPE?.WHEELCHAIR_ACCESSIBLE_ID?.ID == 2">No wheel-chair access</div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center">
                                <iconify-icon icon="material-symbols:info-outline-rounded" matTooltip="{{types.ROOM_TYPE?.ROOM_SPACE_ID?.DETAIL}}" style="color: #EF9E00; font-size: 18px;"></iconify-icon>
                                <div>{{types.ROOM_TYPE?.ROOM_SPACE_ID?.NAME}}</div>
                            </div>
                        </div>
                        <div fxFlex="30%" *ngIf="!types.ROOM_TYPE"></div>
                        <mat-divider [vertical]="true"></mat-divider>
                        <!-- <div fxFlex="10%" class="room-details" style="text-align: center;">2/5</div>
                        <mat-divider [vertical]="true"></mat-divider> -->
                        <div fxFlex="50%" fxLayout="column" *ngIf="types.RATE_PLAN.length != 0">
                            <ng-container *ngFor="let plan of types.RATE_PLAN">
                                <div fxLayout="row">
                                    <div fxFlex="30%" fxLayoutAlign="center center">
                                        <ng-container *ngFor="let occup of plan.RATE_PLAN_APPLICABLE.OCCUPANT | keyvalue">
                                            <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center" *ngIf="occup.key == adultCount">
                                                <div class="main-values">{{currency}} {{occup.value * noOfDays}}</div>
                                                <div class="sub-title">Inclusive of taxes</div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!!plan.RATE_PLAN_APPLICABLE.MESSAGE">
                                            <div class="preferences" style="text-align: center;">{{plan.RATE_PLAN_APPLICABLE.MESSAGE}}</div>
                                        </ng-container>
                                    </div>
                                    <mat-divider [vertical]="true"></mat-divider>
                                    <div fxLayout="row" fxFlex="70%" fxLayoutAlign="space-between center" class="room-details">
                                        <div fxLayout="column" fxLayoutGap="10px">
                                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center" *ngFor="let facility of plan.ROOM_FACILITIES">
                                                <img src="../../../../assets/images/tick.png">
                                                <div class="preferences">{{facility.NAME}}</div>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign=" center" *ngFor="let facility of plan.HOTEL_FACILITIES">
                                                <img src="../../../../assets/images/tick.png">
                                                <div class="preferences">{{facility.NAME}}</div>
                                            </div>
                                        </div>
                                        <ng-container *ngFor="let occup of plan.RATE_PLAN_APPLICABLE.OCCUPANT | keyvalue">
                                            <div *ngIf="occup.key == adultCount">
                                                <button mat-button class="save-btn" (click)="bookRoomType(types.ROOM_TYPE?.ID, types.ROOM_TYPE?.NAME, plan.ID, plan.NAME, occup.value)">Book</button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <mat-divider></mat-divider>
                            </ng-container>
                        </div>
                        <div fxFlex="50%" fxLayoutAlign="center center" *ngIf="types.RATE_PLAN.length == 0">
                            <div class="preferences">Rate plan has not been associated</div>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                </div>
                <div *ngIf="availableRoomTypes.length == 0">
                    <div class="preferences" style="text-align: center; padding: 20px;">No Rooms Available</div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="25px" *ngIf="reserveRoom">
            <div fxFlex="55%" fxLayout="column" fxLayoutGap="15px">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2%">
                    <div class="back-arrow" (click)="backToAvailableRooms()">
                        <iconify-icon icon="material-symbols:arrow-back-rounded"></iconify-icon>
                    </div>
                    <div class="bulk-head">
                        Create Resevation
                    </div>
                </div>
                <mat-divider></mat-divider>
                <form [formGroup]="reserveForm" fxLayout="column" fxLayoutGap="3%">
                    <div mat-dialog-content class="content" fxLayout="column" fxLayoutGap="20px" style="padding-bottom: 10px;">
                        <div class="price-head" style="text-decoration: underline;">Host</div>
                        <div fxLayout="row" fxLayoutGap="35px">
                            <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                <div class="box-name">
                                    First Name
                                </div>
                                <div>
                                    <input type="text" class="text-box" formControlName="firstName"
                                        placeholder="Enter First Name">
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Last Name
                                </div>
                                <div>
                                    <input type="text" class="text-box" formControlName="lastName"
                                        placeholder="Enter Last Name">
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutGap="35px">
                            <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Email ID
                                </div>
                                <div>
                                    <input type="text" class="text-box" formControlName="emailId"
                                        placeholder="Enter Email ID">
                                </div>
                            </div>
                            <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Phone Number
                                </div>
                                <div>
                                    <input type="number" class="text-box" formControlName="phoneNumber"
                                        placeholder="Enter Phone Number">
                                </div>
                            </div>
                        </div>
                        <div formArrayName="guestsArray">
                            <div fxLayout="column" fxLayoutGap="25px"
                                *ngFor="let guestControl of getGuests(); let in = index;" formGroupName="{{in}}" class="array-box">
                                <div class="price-head" style="text-decoration: underline;">Guest {{in+1}}</div>
                                <div fxLayout="row" fxLayoutGap="35px">
                                    <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                        <div class="box-name">
                                            First Name
                                        </div>
                                        <div>
                                            <input type="text" class="text-box" formControlName="guestFirstName" placeholder="Enter First Name">
                                        </div>
                                    </div>
                                    <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                        <div class="box-name">
                                            Last Name
                                        </div>
                                        <div>
                                            <input type="text" class="text-box" formControlName="guestLastName" placeholder="Enter Last Name">
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout="row" fxLayoutGap="35px">
                                    <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                        <div class="box-name">
                                            Email ID
                                        </div>
                                        <div>
                                            <input type="text" class="text-box" formControlName="guestEmail" placeholder="Enter Email ID">
                                        </div>
                                    </div>
                                    <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                        <div class="box-name">
                                            Phone Number
                                        </div>
                                        <div>
                                            <input type="number" class="text-box" formControlName="guestMobileNumber" placeholder="Enter Phone Number">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div fxLayout="row" fxLayoutGap="35px">
                            <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                <div class="box-name">
                                    Payment Mode
                                </div>
                                <div>
                                    <mat-select class="text-box" appearance="fill" formControlName="paymentMode"
                                        placeholder="Select">
                                        <mat-option [value]="1">Card</mat-option>
                                        <mat-option [value]="2">Pay at hotel</mat-option>
                                        <mat-option [value]="3">Google Pay</mat-option>
                                    </mat-select>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="reserveForm.controls['paymentMode'].value == 1">
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Card Number
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="cardNumber"
                                            placeholder="Enter Card Number">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Expiry Date
                                    </div>
                                    <div>
                                        <input type="number" class="text-box" formControlName="expiryDate"
                                            placeholder="Enter Expiry Date">
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Security Code
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="securityCode"
                                            placeholder="Enter Security Code">
                                    </div>
                                </div>
                                <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Name on the Card
                                    </div>
                                    <div>
                                        <input type="text" class="text-box" formControlName="cardHolderName"
                                            placeholder="Enter Name on the Card">
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutGap="35px">
                                <div fxLayout="column" fxFlex="45%" fxLayoutGap="7px">
                                    <div class="box-name">
                                        Payment Amount
                                    </div>
                                    <div>
                                        <input type="number" class="text-box" formControlName="paymentAmount"
                                            placeholder="Enter Payment Amount">
                                    </div>
                                </div>
                            </div>
                        </ng-container> -->
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="3%">
                        <button mat-button class="save-btn" (click)="createReservation()"
                            [disabled]="reserveForm.invalid">Confirm</button>
                        <button mat-button class="cancel-btn" (click)="backToAvailableRooms()">Cancel</button>
                    </div>
                </form>
            </div>
            <div fxFlex="45%">
                <mat-card class="info-card" fxLayout="column">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                        <div class="img-circle">
                            <img src="../../../../../assets/images/reserve_group.png">
                        </div>
                        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">
                            <div class="reserve-detail">Check-In:<span>{{searchWithStartDate.value | date: 'dd/MM/YYYY'}}</span></div>
                            <div class="reserve-detail">Check-Out:<span>{{searchWithEndDate.value | date: 'dd/MM/YYYY'}}</span></div>
                            <div class="reserve-detail">Adults:<span>{{adultCount}}</span></div>
                            <div class="reserve-detail">Children:<span>{{childCount}}</span></div>
                            <div class="reserve-detail">Room Type:<span>{{selectedRoomName}}</span></div>
                            <div class="reserve-detail">Rate Plan:<span>{{selectedPlanName}}</span></div>
                            <div class="reserve-detail">Number of Rooms:<span>{{searchWithRooms.value}}</span></div>
                        </div>
                    </div>
                    <mat-divider style="margin-top: 20px;"></mat-divider>
                    <div fxLayout="column" fxLayoutGap="20px">
                        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                            <ng-container matColumnDef="days">
                                <th mat-header-cell *matHeaderCellDef class="price-head" style="width:25%"></th>
                                <td mat-cell *matCellDef="let element; let i = index;">
                                    <mat-button class="day-button">Night {{i+1}}</mat-button>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="adult">
                                <th mat-header-cell *matHeaderCellDef class="price-head" style="width:20%"> Adult </th>
                                <td mat-cell *matCellDef="let element" class="price-detail">{{element.adultAmount}}</td>
                            </ng-container>
                            <ng-container matColumnDef="child">
                                <th mat-header-cell *matHeaderCellDef class="price-head" style="width:20%"> Child </th>
                                <td mat-cell *matCellDef="let element" class="price-detail">{{element.childAmount}}</td>
                            </ng-container>
                            <ng-container matColumnDef="tax">
                                <th mat-header-cell *matHeaderCellDef class="price-head" style="width:15%"> Tax </th>
                                <td mat-cell *matCellDef="let element" class="price-detail">{{element.taxAmount}}</td>
                            </ng-container>
                            <ng-container matColumnDef="total">
                                <th mat-header-cell *matHeaderCellDef class="price-head" style="width:20%"> Total </th>
                                <td mat-cell *matCellDef="let element" class="price-total">{{element.totalAmount}}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
                        </table>
                        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
                            <div class="price-head">Grand Total</div>
                            <mat-button class="sum-button">{{grandTotalAmount}}</mat-button>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</div>