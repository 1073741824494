import { Component, HostListener, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HotelServicesService } from 'src/app/services/hotel-services.service';
import { NotificationService } from 'src/app/services/notification.service';
import { LogoutConfirmationComponent } from '../../logout-confirmation/logout-confirmation.component';
import { colorObj } from 'src/app/shared/color-object';
import { MatTableDataSource } from '@angular/material/table';
import { MatMenuTrigger } from '@angular/material/menu';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-reservation',
  templateUrl: './add-reservation.component.html',
  styleUrls: ['./add-reservation.component.scss']
})
export class AddReservationComponent {

  loading: boolean = false;
  userInfo: any;
  userName: any;
  accessType: any;
  currencyInfo: any;
  currency: any;
  searchPlans: boolean = true;
  availableRooms: boolean = false;
  reserveRoom: boolean = false;
  searchRatePlanForm: FormGroup;
  reserveForm: FormGroup;
  currentDate = new Date();
  minToDate: Date;
  searchWithRooms = new FormControl();
  searchWithOccupants = new FormControl('');
  searchWithStartDate = new FormControl('');
  searchWithEndDate = new FormControl('');
  noOfDays: number = 1;
  roomCategories = [];
  displayedColumns: string[] = ['days', 'adult', 'child', 'tax', 'total'];
  dataSource = new MatTableDataSource<any>();
  adultCount: number = 1;
  childCount: number = 0;
  updatedAdultCount: number = 1;
  updatedChildCount: number = 0;
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  availableRoomTypes = [];
  selectedRoomID;
  selectedRoomName;
  selectedPlanID;
  selectedPlanName;
  grandTotalAmount: number;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialog: MatDialog,
    public notify: NotificationService,
    private hotelService: HotelServicesService,
    private router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.searchRatePlanForm = this.fb.group({
      // planProperties: [[], Validators.required],
      // bookingService: ['', Validators.required],
      checkInDate: ['', Validators.required],
      checkOutDate: ['', Validators.required],
      noOfRooms: ['', Validators.required],
      roomOccupants: [''],
      roomCategory: [''],
      reservationType: ['']
    })
    this.reserveForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailId: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      guestsArray: fb.array([])
      // paymentMode: [''],
      // cardNumber: [''],
      // expiryDate: [''],
      // securityCode: [''],
      // cardHolderName: [''],
      // paymentAmount: ['']
    })
  }

  ngOnInit() {
    this.checkScreenWidth();
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    this.userName = this.userInfo.firstName;
    this.accessType = this.userInfo.accessTypeId;
    this.currencyInfo = JSON.parse(window.localStorage.getItem('currencyInfo'));
    if (this.currencyInfo != null) {
      this.currency = this.currencyInfo.currency == null || undefined || '' ? '' : this.currencyInfo.currency;
    }
    this.getCategories();
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  getCategories() {
    this.hotelService.getRoomCategory().subscribe({
      next: (data) => {
        this.roomCategories = data.object;
      },
      error: (e) => {
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  selectDate() {
    this.minToDate = new Date(this.searchRatePlanForm.value.checkInDate);
    this.searchRatePlanForm.controls['checkOutDate'].reset();
  }

  increaseAdultCount(event: Event) {
    event.stopPropagation();
    this.adultCount++;
  }

  decreaseAdultCount(event: Event) {
    event.stopPropagation();
    if (this.adultCount > 1) {
      this.adultCount--;
    }
  }

  increaseChildCount(event: Event) {
    event.stopPropagation();
    this.childCount++;
  }

  decreaseChildCount(event: Event) {
    event.stopPropagation();
    if (this.childCount > 0) {
      this.childCount--;
    }
  }

  updateCounts() {
    this.updatedAdultCount = this.adultCount;
    this.updatedChildCount = this.childCount;
    this.searchRatePlanForm.controls['roomOccupants'].setValue(`Adult - ${this.updatedAdultCount}, Child - ${this.updatedChildCount}`);
    this.searchWithOccupants.setValue(`${this.updatedAdultCount} Adults, ${this.updatedChildCount} Children`);
    this.menuTrigger.closeMenu();
  }

  menuClosed() {
    this.adultCount = this.updatedAdultCount;
    this.childCount = this.updatedChildCount;
  }

  searchRatePlans() {
    var formattedDateFrom;
    var formattedDateTo
    var noOfRooms;
    if (this.searchPlans == true) {
      if (new Date(this.searchRatePlanForm.value.checkOutDate) > new Date(this.searchRatePlanForm.value.checkInDate)) {
        formattedDateFrom = this.datePipe.transform(this.searchRatePlanForm.value.checkInDate, 'dd-MM-yyyy');
        formattedDateTo = this.datePipe.transform(this.searchRatePlanForm.value.checkOutDate, 'dd-MM-yyyy');
        if (this.adultCount >= this.searchRatePlanForm.value.noOfRooms) {
          noOfRooms = this.searchRatePlanForm.value.noOfRooms;
          this.loading = true;
          this.hotelService.getSearchedRoomTypes(formattedDateFrom, formattedDateTo, noOfRooms, this.adultCount, this.childCount, this.searchRatePlanForm.value.roomCategory).subscribe({
            next: (data) => {
              this.loading = false;
              this.searchPlans = false;
              this.availableRooms = true;
              this.noOfDays = this.calculateDateDiff(this.searchRatePlanForm.value.checkInDate, this.searchRatePlanForm.value.checkOutDate);
              this.availableRoomTypes = data.object;
              this.searchWithStartDate.setValue(this.datePipe.transform(this.searchRatePlanForm.value.checkInDate, 'yyyy-MM-dd'));
              this.searchWithEndDate.setValue(this.datePipe.transform(this.searchRatePlanForm.value.checkOutDate, 'yyyy-MM-dd'));
              this.searchWithRooms.setValue(this.searchRatePlanForm.value.noOfRooms);
              this.searchWithOccupants.setValue(`${this.updatedAdultCount} Adults, ${this.updatedChildCount} Children`);
              for (let room of this.availableRoomTypes) {
                for (let rate of room.RATE_PLAN) {
                  rate.RATE_PLAN_APPLICABLE = {};
                  for (let plan of rate.RATE_PLAN_PRICING) {
                    let retValue: boolean = false;
                    retValue = (new Date(this.searchWithStartDate.value) >= new Date(plan.DATE_FROM)) && (new Date(this.searchWithEndDate.value) <= new Date(plan.DATE_TO));
                    if (retValue == true) {
                      rate.RATE_PLAN_APPLICABLE = plan;
                    }
                  }
                  if (Object.keys(rate.RATE_PLAN_APPLICABLE).length == 0) {
                    rate.RATE_PLAN_APPLICABLE.MESSAGE = "Rate plan doesn't have pricing between these dates";
                  }
                }
              }
            },
            error: (e) => {
              this.loading = false;
              this.notify.showNotification(
                e.error.message,
                "top",
                (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
                e.error.status
              )
              this.searchPlans = true;
              this.availableRooms = false;
            }
          })
        }
        else {
          this.notify.showMessage(
            "Number of adults should be greater than or equal to number of rooms",
            "top",
            "warning"
          )
        }
      }
      else {
        this.notify.showMessage(
          "Check out date should be greater than check in date",
          "top",
          "warning"
        )
      }
    }
    else if (this.availableRooms == true) {
      if (new Date(this.searchWithEndDate.value) > new Date(this.searchWithStartDate.value)) {
        formattedDateFrom = this.datePipe.transform(this.searchWithStartDate.value, 'dd-MM-yyyy');
        formattedDateTo = this.datePipe.transform(this.searchWithEndDate.value, 'dd-MM-yyyy');
        if (this.adultCount >= this.searchWithRooms.value) {
          noOfRooms = this.searchWithRooms.value;
          this.loading = true;
          this.hotelService.getSearchedRoomTypes(formattedDateFrom, formattedDateTo, noOfRooms, this.adultCount, this.childCount, this.searchRatePlanForm.value.roomCategory).subscribe({
            next: (data) => {
              this.loading = false;
              this.searchPlans = false;
              this.availableRooms = true;
              this.noOfDays = this.calculateDateDiff(this.searchWithStartDate.value, this.searchWithEndDate.value);
              this.availableRoomTypes = data.object;
              this.searchRatePlanForm.controls['checkInDate'].setValue(this.datePipe.transform(this.searchWithStartDate.value, 'yyyy-MM-dd'));
              this.searchRatePlanForm.controls['checkOutDate'].setValue(this.datePipe.transform(this.searchWithEndDate.value, 'yyyy-MM-dd'));
              this.searchRatePlanForm.controls['noOfRooms'].setValue(this.searchWithRooms.value);
              for (let room of this.availableRoomTypes) {
                for (let rate of room.RATE_PLAN) {
                  rate.RATE_PLAN_APPLICABLE = {};
                  for (let plan of rate.RATE_PLAN_PRICING) {
                    let retValue: boolean = false;
                    retValue = (new Date(this.searchWithStartDate.value) >= new Date(plan.DATE_FROM)) && (new Date(this.searchWithEndDate.value) <= new Date(plan.DATE_TO));
                    if (retValue == true) {
                      rate.RATE_PLAN_APPLICABLE = plan;
                    }
                  }
                  if (Object.keys(rate.RATE_PLAN_APPLICABLE).length == 0) {
                    rate.RATE_PLAN_APPLICABLE.MESSAGE = "Rate plan doesn't have pricing between these dates";
                  }
                }
              }
            },
            error: (e) => {
              this.loading = false;
              this.notify.showNotification(
                e.error.message,
                "top",
                (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
                e.error.status
              )
              this.searchPlans = true;
              this.availableRooms = false;
            }
          })
        }
        else {
          this.notify.showMessage(
            "Number of adults should be greater than or equal to number of rooms",
            "top",
            "warning"
          )
        }
      }
      else {
        this.notify.showMessage(
          "Check out date should be greater than check in date",
          "top",
          "warning"
        )
      }
    }
  }

  calculateDateDiff(checkInDate, checkOutDate) {
    checkInDate = new Date(checkInDate);
    checkOutDate = new Date(checkOutDate);
    return Math.floor((Date.UTC(checkOutDate.getFullYear(), checkOutDate.getMonth(), checkOutDate.getDate()) - Date.UTC(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate())) / (1000 * 60 * 60 * 24));
  }

  cancelSearch() {
    this.searchRatePlanForm.reset();
    this.adultCount = 1;
    this.childCount = 0;
  }

  backToSearchRooms() {
    this.availableRooms = false;
    this.searchPlans = true;
  }

  bookRoomType(roomId, roomName, planId, planName, occupantAmount) {
    this.availableRooms = false;
    this.reserveRoom = true;
    for (let i = 1; i < this.adultCount; i++) {
      this.addGuests();
    }
    this.selectedRoomID = roomId;
    this.selectedRoomName = roomName;
    this.selectedPlanID = planId;
    this.selectedPlanName = planName;
    for (let i = 0; i < this.noOfDays; i++) {
      let obj = {
        adultAmount: occupantAmount * this.searchWithRooms.value,
        childAmount: 0,
        taxAmount: 0,
        totalAmount: occupantAmount * this.searchWithRooms.value
      }
      this.dataSource.data.push(obj);
    }
    this.grandTotalAmount = this.dataSource.data.reduce((grandTotal: any, value: any) => grandTotal + value?.totalAmount, 0);
  }

  backToAvailableRooms() {
    this.reserveRoom = false;
    this.availableRooms = true;
    this.dataSource.data = [];
    (this.reserveForm.controls['guestsArray'] as FormArray).clear();
  }

  getGuests() {
    return (this.reserveForm.get('guestsArray') as FormArray).controls;
  }

  addGuests() {
    let codeIndex = (<FormArray>this.reserveForm.get('guestsArray')).length;
    if (codeIndex < 20) {
      let fg = this.fb.group({
        guestFirstName: this.fb.control(''),
        guestLastName: this.fb.control(''),
        guestEmail: this.fb.control(''),
        guestMobileNumber: this.fb.control('')
      });
      (<FormArray>this.reserveForm.get('guestsArray')).push(fg);
    }
  }

  createReservation() {
    this.loading = true;
    const formattedDateFrom = this.datePipe.transform(this.searchWithStartDate.value, 'dd-MM-yyyy');
    const formattedDateTo = this.datePipe.transform(this.searchWithEndDate.value, 'dd-MM-yyyy');
    let formData: any;
    formData = this.reserveForm.value;
    let secondaryGuest = formData['guestsArray'];
    let secondaryGuestsArray = [];
    for (let i = 0; i < secondaryGuest.length; i++) {
      if (!!secondaryGuest[i].guestFirstName) {
        secondaryGuestsArray.push({
          "USER_TYPE": "2",
          "FIRST_NAME": secondaryGuest[i].guestFirstName,
          "LAST_NAME": secondaryGuest[i].guestLastName,
          "MOBILE": secondaryGuest[i].guestMobileNumber,
          "EMAIL_ID": secondaryGuest[i].guestEmail,
          "VERIFICATION_DOCUMENT_TYPE_ID": null,
          "VERIFICATION_DOCUMENT_ATTACHMENT_SOURCE": ""
        })
      }
    }
    let obj = {
      "FROM_DATE": formattedDateFrom,
      "TO_DATE": formattedDateTo,
      "HOST": {
        "USER_TYPE": "1",
        "FIRST_NAME": this.reserveForm.value.firstName,
        "LAST_NAME": this.reserveForm.value.lastName,
        "MOBILE": this.reserveForm.value.phoneNumber,
        "EMAIL_ID": this.reserveForm.value.emailId,
        "VERIFICATION_DOCUMENT_TYPE_ID": null,
        "VERIFICATION_DOCUMENT_ATTACHMENT_SOURCE": ""
      },
      "GUEST": secondaryGuestsArray,
      "ROOMS": this.searchWithRooms.value,
      "ADULT_OCCUPANTS": this.adultCount,
      "CHILD_OCCUPANTS": this.childCount,
      "RESERVATION_STATUS_ID": "1",
      "ROOM_DATA": {
        "ROOM_TYPE_ID": this.selectedRoomID,
        "RATE_PLAN_ID": this.selectedPlanID
      }
    }
    this.hotelService.createReservation(obj).subscribe({
      next: (data) => {
        this.loading = false;
        this.notify.showNotification(
          data.message,
          "top",
          (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
          data.status
        );
        this.searchRatePlanForm.reset();
        this.reserveForm.reset();
        this.router.navigateByUrl('all-reservations');
      },
      error: (e) => {
        this.loading = false;
        this.notify.showNotification(
          e.error.message,
          "top",
          (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
          e.error.status
        )
      }
    })
  }

  cancelReservation() {
    this.reserveForm.reset();
  }

  logout() {
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      width: '343px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'yes') {
        window.localStorage.clear();
        this.router.navigate(['auth/login']);
      }
    });
  }

}