import { Component, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-add-edit-tier-category',
  templateUrl: './add-edit-tier-category.component.html',
  styleUrls: ['./add-edit-tier-category.component.scss']
})
export class AddEditTierCategoryComponent {
  loading: boolean = false;
  tierCategoryForm: FormGroup;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddEditTierCategoryComponent>,
    private fb: FormBuilder,
    private cs: ConfigurationService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.tierCategoryForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.checkScreenWidth();
    if (this.data) {
      this.loading = true;
      this.cs.getTierCategoryById(this.data.id).subscribe({
        next: (res) => {
          this.loading = false;
          this.tierCategoryForm.get("name").setValue(res.object.NAME);
          this.tierCategoryForm.get("description").setValue(res.object.DESCRIPTION);
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  addTierCategory() {
    this.loading = true;
    if (this.data != null) {
      let obj = {
        "ID": this.data.id,
        "NAME": this.tierCategoryForm.value.name,
        "DESCRIPTION": this.tierCategoryForm.value.description
      }
      this.cs.updateTierCategory(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    } else {
      let obj = {
        "NAME": this.tierCategoryForm.value.name,
        "DESCRIPTION": this.tierCategoryForm.value.description
      }
      this.cs.addTierCategory(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }
}
