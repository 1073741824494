import { Component, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { colorObj } from 'src/app/shared/color-object';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-add-edit-market-segment',
  templateUrl: './add-edit-market-segment.component.html',
  styleUrls: ['./add-edit-market-segment.component.scss']
})
export class AddEditMarketSegmentComponent {
  loading: boolean = false;
  marketSegmentForm: FormGroup;
  mobile: boolean;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  constructor(
    public dialogRef: MatDialogRef<AddEditMarketSegmentComponent>,
    private fb: FormBuilder,
    private cs: ConfigurationService,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.marketSegmentForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.checkScreenWidth();
    if (this.data) {
      this.loading = true;
      this.cs.getMarketSegmentById(this.data.id).subscribe({
        next: (res) => {
          this.loading = false;
          this.marketSegmentForm.get("name").setValue(res.object.NAME);
          this.marketSegmentForm.get("description").setValue(res.object.DESCRIPTION);
        },
        error: (e) => {
          this.loading = false;
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }

  private checkScreenWidth() {
    if (window.innerWidth <= 1250) {
      this.dialogRef.close('cancel');
    } else {
      this.mobile = false;
    }
  }

  cancel() {
    this.dialogRef.close('cancel');
  }

  addMarketSegment() {
    this.loading = true;
    if (this.data != null) {
      let obj = {
        "ID": this.data.id,
        "NAME": this.marketSegmentForm.value.name,
        "DESCRIPTION": this.marketSegmentForm.value.description
      }
      this.cs.updateMarketSegment(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close(data.object);
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    } else {
      let obj = {
        "NAME": this.marketSegmentForm.value.name,
        "DESCRIPTION": this.marketSegmentForm.value.description
      }
      this.cs.addMarketSegment(obj).subscribe({
        next: (data) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            data.message,
            "top",
            (!!colorObj[data.status] ? colorObj[data.status.status] : "success"),
            data.status
          );
        },
        error: (e) => {
          this.loading = false;
          this.dialogRef.close();
          this.notify.showNotification(
            e.error.message,
            "top",
            (!!colorObj[e.error.status] ? colorObj[e.error.status] : "error"),
            e.error.status
          )
        }
      })
    }
  }
}
