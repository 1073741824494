import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  public resourceUrl = `${environment.API_BASE_URL}`;

  constructor(private http: HttpClient) { }

  getAreas(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/area`);
  }

  getAreaBySearchKey(key): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/area?SEARCH_KEY=${key}`);
  }

  getAreaById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/area?ID=${id}`);
  }

  addArea(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/configuration/area/create`, obj);
  }

  updateArea(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/configuration/area/update`, obj);
  }

  getRooms(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/room`);
  }

  getRoomBySearchKey(key): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/room?SEARCH_KEY=${key}`);
  }

  getRoomById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/room?ID=${id}`);
  }

  getRoomsByAreaId(areaId): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/room?AREA_ID=${areaId}`);
  }

  addRoom(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/configuration/room/create`, obj);
  }

  updateRoom(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/configuration/room/update`, obj);
  }

  getShifts(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/work-shift`);
  }

  getShiftBySearchKey(key): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/work-shift?SEARCH_KEY=${key}`);
  }

  getShiftById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/work-shift?ID=${id}`);
  }

  addShift(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/configuration/work-shift/create`, obj);
  }

  updateShift(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/configuration/work-shift/update`, obj);
  }

  getRoomAllocations(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/room/allocate`);
  }

  getRoomAllocationById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/room/allocate?ID=${id}`);
  }

  getRoomAllocationByMemberId(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/room/allocate?MEMBER_ID=${id}`);
  }

  updateRoomAllocation(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/configuration/room/allocate/update`, obj);
  }

  getServiceTimings(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/services/timing`);
  }

  getServiceTimingById(s_id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/services/timing?S_ID=${s_id}`);
  }

  updateServiceTiming(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/services/timing`, obj);
  }

  getThemes(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/theme`);
  }

  updateThemes(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/configuration/theme`, obj);
  }

  getTax(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/tax`);
  }

  getTaxBySearchKey(key, service): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/tax?SEARCH_KEY=${key}&SERVICE_ID=${service}`);
  }

  getTaxById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/tax?ID=${id}`);
  }

  addTax(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/configuration/tax/create`, obj);
  }

  updateTax(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/configuration/tax/update`, obj);
  }

  getCancellationPolicy(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/cancellation-policy`);
  }

  getCancellationPolicyId(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/cancellation-policy?ID=${id}`);
  }

  addCancellationPolicy(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/room/cancellation-policy/create`, obj);
  }

  updateCancellationPolicy(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/room/cancellation-policy/update`, obj);
  }

  getRatePlan(boolean): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/room/rate?TRIM=${boolean}`);
  }

  getMarketSegment(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/market-segment`);
  }

  getMarketSegmentBySearchKey(key): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/market-segment?SEARCH_KEY=${key}`);
  }

  updateMarketSegment(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/configuration/market-segment/update`, obj);
  }

  addMarketSegment(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/configuration/market-segment/create`, obj);
  }

  getMarketSegmentById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/market-segment?ID=${id}`);
  }

  getTierCategory(): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/tier-category`);
  }

  getTierCategoryBySearchKey(key): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/tier-category?SEARCH_KEY=${key}`);
  }

  updateTierCategory(obj): Observable<any> {
    return this.http.put<any>(`${this.resourceUrl}/web/gofryday/configuration/tier-category/update`, obj);
  }

  addTierCategory(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/configuration/tier-category/create`, obj);
  }

  getTierCategoryById(id): Observable<any> {
    return this.http.get<any>(`${this.resourceUrl}/web/gofryday/configuration/tier-category?ID=${id}`);
  }



}