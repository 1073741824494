import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, firstValueFrom, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  public resourceUrl = `${environment.API_BASE_URL}`;

  constructor(private http: HttpClient) { }

  getPreSignedUrl(obj: Object) {
    let payloadObj = {
      "SERVICE_ID": obj['SERVICE_ID'],
      "SECTION": obj['SECTION'],
      "FILE_NAME": obj['FILE_NAME']
    }
    return firstValueFrom(this.http.post<any>(`${this.resourceUrl}/web/gofryday/services/presigned-url`, payloadObj));
  }

  getPreSignedUrlforClientLogo(obj: Object) {
    let payloadObj = {
      "C_ID": obj['C_ID'],
      "FILE_NAME": obj['FILE_NAME']
    }
    return firstValueFrom(this.http.post<any>(`${this.resourceUrl}/web/gofryday/client/logo/presigned-url`, payloadObj));
  }

  getPreSignedUrlforCustomerSheet(obj: Object) {
    let payloadObj = {
      "FILE_NAME": obj['FILE_NAME']
    }
    return firstValueFrom(this.http.post<any>(`${this.resourceUrl}/web/gofryday/user/upload/presigned-url`, payloadObj));
  }

  saveToDBCustomerSheet(obj): Observable<any> {
    return this.http.post<any>(`${this.resourceUrl}/web/gofryday/user/upload`, obj);
  }

  uploadFilethroughSignedUrl(obj: Object): Promise<any> {
    return this.http.put<any>(obj['url'], obj['file'], { reportProgress: true, observe: 'events' })
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event.type !== HttpEventType.UploadProgress) return;
          const progress = Math.round(event.loaded * 100 / event.total);
        }),
      )
      .toPromise();
  }

}